@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

@media only screen and (min-width: 800px) {
  /* hide element on small screens */
  input { 
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    text-align: center;
    overflow:hidden;
    width:450px;
  }

  .App {
    padding:1rem 5rem 0
  }
}

body {
  font-family: 'Open Sans', sans-serif;
}

input { 
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  text-align: center;
  overflow:hidden
}

a{
  text-decoration: none;
  color: inherit;
}

.App {
  padding:1rem 1rem 0
}

.header{
  margin:1rem;
}

.textarea {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  height: 57px;
  border: 1px solid gray;
  border-radius: 32px;
  margin: auto;
}

.textarea-text {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
}

.sample-query {
  padding:20px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  background-color: rgba(32,151,243, 0.2);
  margin: 5px 0;
}

.sample-query:hover{
  background: black;
  color:white
}

.limitation{
  padding:20px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  background-color: rgba(166,176,183, 0.2);
  margin: 5px 0;
}

.answer-block{
  text-align: left;
  padding: 24px;
  margin-top: 3rem;
  border-radius: 8px;
  border: 1px solid gray;
  min-height:44px;
}

.link {
  text-decoration: underline;
  color:#2097F3
}

.flex {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 3rem;
}
.flex > div {
  flex: 1 0 0;
}
